import { FCM_CAPYBARA_HOMEPAGE_V3, FCM_CAPYBARA_HOMEPAGE_V3_CONTENT } from '@change-corgi/fcm/configs';

import { createFcmContext } from 'src/app/shared/contexts/fcm';
import type { LoadedStateData } from 'src/app/shared/utils/async';

export const {
	FcmContext: HomepagePageFcmContext,
	FcmProvider: HomepagePageFcmContextProvider,
	useFcm: useHomepagePageFcm,
	useAsyncFcm: useAsyncHomepagePageFcm,
	prefetchFcm: prefetchHomepagePageFcm,
} = createFcmContext({
	name: 'HomepagePageFcmContext',
	dataProperty: 'pageFcm',
	configs: {
		homepageV3: FCM_CAPYBARA_HOMEPAGE_V3,
		homepageV3Content: FCM_CAPYBARA_HOMEPAGE_V3_CONTENT,
	},
	prefetch: true,
});

export type HomepagePageFcmState = ReturnType<typeof useAsyncHomepagePageFcm>;
export type HomepagePageFcm = LoadedStateData<HomepagePageFcmState>;
