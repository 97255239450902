import { createPrefetchableAsyncUserDataContext, pageDataErrorHandler } from 'src/app/shared/contexts/asyncData';

import { getHomepagePageUserData } from './api';
import type { HomepagePageUserDataErrorCode } from './types';

export const {
	Context: HomepagePageUserDataContext,
	Provider: HomepagePageUserDataContextProvider,
	useContext: useHomepagePageUserData,
	useAsyncData: useHomepagePageUserDataFromApi,
	prefetchAsyncData: prefetchHomepagePageUserDataFromApi,
} = createPrefetchableAsyncUserDataContext({
	name: 'HomepagePageUserDataContext',
	dataProperty: 'pageUserData',
	getData: getHomepagePageUserData,
	errorHandler: pageDataErrorHandler<HomepagePageUserDataErrorCode>(),
});
