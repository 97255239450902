import React from 'react';

import { ThemeProvider } from '@change/tomato-core-ui';

import { ClientRender } from '@change-corgi/core/react/ssr/render';
import { Loader } from '@change-corgi/design-system/components/progressiveDisclosure';

import { ContributeSection } from './components/ContributeSection';
import { HeroSection } from './components/HeroSection';
import { HeroSectionV3 } from './components/HeroSectionV3';
import { HowToSection } from './components/HowToSection';
import { HowToSectionV3 } from './components/HowToSectionV3';
import { LocalSection } from './components/LocalSection';
import { LocalSectionOld } from './components/LocalSectionOld';
import { TrendingSection } from './components/TrendingSection';
import { usePageBody } from './hooks/usePageBody';

export function PageBody(): React.JSX.Element {
	const {
		data: { showHeroV3, showHowToV3, showRelease3 },
	} = usePageBody();

	return (
		<ThemeProvider>
			{showHeroV3 ? <HeroSectionV3 /> : <HeroSection />}
			{showHowToV3 && <HowToSectionV3 />}

			<div className="container space-y-16 py-6 md:py-16">
				{!showHowToV3 && <HowToSection />}
				<ClientRender placeholder={<Loader size="m" my={256} mx="auto" />}>
					{showRelease3 ? <LocalSection /> : <LocalSectionOld />}
				</ClientRender>
				<TrendingSection />
				<ContributeSection />
			</div>
		</ThemeProvider>
	);
}
