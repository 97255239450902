import type { JSX } from 'react';

import { PageBody } from '../components/PageBody';
import { PageMeta } from '../components/PageMeta';
import { PageOrganizationSchema } from '../components/PageOrganizationSchema';

import { useTrackPageView } from './hooks/useTrackPageView';

export function Layout(): JSX.Element {
	useTrackPageView();

	return (
		<>
			<PageMeta />
			<PageOrganizationSchema />
			<PageBody />
		</>
	);
}
