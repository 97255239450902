import { useEffect, useMemo } from 'react';

import { useQuery } from '@tanstack/react-query';

import { useTracking } from '@change-corgi/core/react/tracking';
import { useUtilityContext } from '@change-corgi/core/react/utilityContext';

import { useLocale } from 'src/app/shared/hooks/l10n';

import { getLocalSectionData } from './api';
import { useUserLocation } from './useUserLocation';

type UserLocation = ReturnType<typeof useUserLocation>['userLocation'];
type LocalData = Awaited<ReturnType<typeof getLocalSectionData>>;
type Result = ModelHookResult<
	{
		localDataValue:
			| {
					shouldHide: true;
					value: null;
			  }
			| {
					shouldHide: false;
					value: {
						userLocation: NonNullable<UserLocation>;
						localData: NonNullable<LocalData>;
						localPageUrl: string;
					};
			  };
	},
	{
		onLocalSectionClick: () => void;
	}
>;

const ROOT_KEY = 'HOMEPAGE_V2_LOCAL_SECTION_LOCATION_BY_SLUG';

export function useLocalSectionOld(): Result {
	const { userLocation } = useUserLocation();
	const utilityContext = useUtilityContext();
	const track = useTracking();
	const locale = useLocale();

	const shouldFetch = !!userLocation;
	const queryState = useQuery({
		queryKey: [ROOT_KEY, userLocation, locale],
		// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
		queryFn: async () => getLocalSectionData(userLocation!.slug, locale, utilityContext),
		enabled: shouldFetch,
	});

	const localData = (queryState.isFetched && queryState.data) || null;
	const localPageUrl = `/r/${userLocation?.slug}`;

	const localDataValue = useMemo(() => {
		const hidden = { shouldHide: true, value: null } as const;

		// if we don't have a location, we won't fetch at all
		if (!shouldFetch) return hidden;

		// `localData` is null when we're loading or there is no data (covers error case)
		if (!localData) return hidden;

		// if all the counts are 0, we don't want to show the section
		if (!localData.signaturesCount && !localData.petitionsCount && !localData.decisionMakersCount) return hidden;

		// return the local data
		return { shouldHide: false, value: { userLocation, localData, localPageUrl } } as const;
	}, [shouldFetch, localData, userLocation, localPageUrl]);

	useEffect(() => {
		if (shouldFetch && !queryState.isFetched) return;

		void track('homepage_local_data_loaded', {
			user_location: userLocation?.name,
			slug: userLocation?.slug,
			has_local_page_data: !!localData,
		});
	}, [localData, queryState.isFetched, shouldFetch, track, userLocation?.name, userLocation?.slug]);

	return {
		data: { localDataValue },
		actions: {
			onLocalSectionClick: () => {
				void track('homepage_local_page_click', {
					location: userLocation?.shortName,
					slug: userLocation?.slug,
				});
			},
		},
	};
}
