import { useTracking } from '@change-corgi/core/react/tracking';

import { useHomepagePageFcm } from 'src/app/pages/homepageV2/pageContext';
import { useLocale } from 'src/app/shared/hooks/l10n';

type Tip = {
	title: string;
	guideLink: string;
};
type Result = ModelHookResult<
	{
		tips: readonly Tip[];
	},
	{
		onTipLinkClick: (url: string) => void;
	}
>;

export function useTipsAndGuides(): Result {
	const { homepageV3Content } = useHomepagePageFcm();

	const locale = useLocale();

	const track = useTracking();
	return {
		data: {
			tips: homepageV3Content[locale]?.tips || [],
		},
		actions: {
			onTipLinkClick: (url: string) => {
				void track('homepage_tips_and_guides_link_click', { url });
			},
		},
	};
}
