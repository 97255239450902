import { Button, Surface, Text } from '@change/tomato-core-ui';

import { Translate, TranslatePlural } from '@change-corgi/core/react/i18n';
import { Link as RouterLink } from '@change-corgi/core/react/router';

import { useSapCtas } from '../../shared/hooks/useSapCtas';

import { MarqueeSection } from './components/MarqueeSection';

export const HeroSection: React.FC = () => {
	const { actions, data } = useSapCtas();

	return (
		<Surface as="section" aria-labelledby="hero-section-title" color="surface-neutral">
			<div className="container space-y-10 px-4 py-6 md:space-y-16 md:px-0 md:py-16">
				<div className="space-y-10 text-center sm:space-y-6">
					<Text className="m-0" id="hero-section-title" variant="display" as="h1">
						<Translate value="corgi.homepage.hero_section.title" />
						<span className="font-[serif] text-content-highlight">.</span>
					</Text>

					<Text as="p" variant="heading" size="small" fontWeight="light" className="mx-auto my-0 max-w-[650px]">
						<TranslatePlural
							count={data.totalUserCount.total}
							value="corgi.homepage.hero_section.description"
							replacements={{ totalUserCount: data.totalUserCount.totalLocalized }}
						/>
					</Text>
					<div className="flex flex-col items-center justify-center gap-5 md:flex-row">
						<Button
							as={RouterLink}
							data-testid="homepage-hero-primary-cta"
							data-qa="homepage-hero-primary-cta"
							to={data.primaryCtaUrl}
							variant="primary"
							className="max-w-full self-stretch md:self-auto"
							onClick={actions.onPrimaryCtaClick}
						>
							<Translate value="corgi.homepage.hero_section.primary_button_text" />
						</Button>
						<Button
							as={RouterLink}
							data-testid="homepage-hero-secondary-cta"
							data-qa="homepage-hero-secondary-cta"
							to={data.secondaryCtaUrl}
							variant="secondary"
							className="max-w-full self-stretch md:self-auto"
							onClick={actions.onSecondaryCtaClick}
						>
							<Translate value="corgi.homepage.hero_section.secondary_button_text" />
						</Button>
					</div>
				</div>
			</div>
			<div className="space-y-10">
				<MarqueeSection />
			</div>
		</Surface>
	);
};
