import { useHomepagePageData, useHomepagePageFcm } from 'src/app/pages/homepageV2/pageContext';
import type { HomepagePageData } from 'src/app/pages/homepageV2/pageContext';
import { useHomepagePageUserData } from 'src/app/pages/homepageV2/pageContext/pageUserData';

type Result = ModelHookResult<{
	trendingPetitions: HomepagePageData['trendingPetitions'];
	trendingTopics: HomepagePageData['trendingTopics'];
	showHeroV3: boolean;
	showHowToV3: boolean;
	showRelease3: boolean;
}>;

export function usePageBody(): Result {
	const {
		data: { trendingPetitions, trendingTopics },
	} = useHomepagePageData();

	const {
		data: { showV3, showRelease3 },
	} = useHomepagePageUserData();

	const {
		homepageV3: {
			layout: { hero, howTo },
		},
	} = useHomepagePageFcm();

	return {
		data: {
			trendingPetitions,
			trendingTopics,
			showHeroV3: showV3 && hero,
			showHowToV3: showV3 && howTo,
			showRelease3,
		},
	};
}
