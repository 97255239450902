import { useQueryParamValue } from 'src/app/shared/hooks/location';
import { useTrackView } from 'src/app/shared/hooks/tracking';

export function useTrackPageView(): void {
	const sourceLocation = useQueryParamValue('source_location');

	useTrackView('homepage_view', {
		source_location: sourceLocation,
	});
}
