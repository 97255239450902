import styled from '@emotion/styled';

import { Accordion, Link, Surface, Text } from '@change/tomato-core-ui';
import { ArrowRightIcon } from '@change/tomato-icons';

import { Translate } from '@change-corgi/core/react/i18n';

import { useTipsAndGuides } from './hooks/useTipsAndGuides';

export const TipsAndGuides: React.FC = () => {
	const StyledAccordion = styled(Accordion)`
		button:hover {
			background-color: transparent !important;
		}
	`;

	const {
		data: { tips },
		actions: { onTipLinkClick },
	} = useTipsAndGuides();

	return (
		<Surface color="surface-accent-secondary">
			<div className="container">
				<div className="flex justify-center">
					<StyledAccordion
						title={
							<Text variant="body" className="font-normal text-content-accent">
								<Translate value="corgi.homepage.how_to.tips_and_guides.title" />
							</Text>
						}
						className="bg-accent-secondary w-full md:w-[50%]"
						variant="un-contained"
					>
						{tips.map((tip) => (
							<Link
								key={tip.title}
								href={tip.guideLink}
								underline={false}
								className="group block"
								target="_blank"
								onClick={() => onTipLinkClick(tip.guideLink)}
							>
								<div className="border-t border-stroke-accent-tertiary py-4 hover:underline focus:underline">
									<div className="flex justify-between">
										<Text variant="body">{tip.title}</Text>
										<ArrowRightIcon
											size="medium"
											className="transition-transform duration-200 ease-out group-hover:translate-x-1"
										/>
									</div>
								</div>
							</Link>
						))}
					</StyledAccordion>
				</div>
			</div>
		</Surface>
	);
};
