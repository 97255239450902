import { Waypoint } from 'react-waypoint';

import { Button, HeroLayout, Image, Surface, Text } from '@change/tomato-core-ui';

import { Translate, useI18n } from '@change-corgi/core/react/i18n';
import { Link } from '@change-corgi/core/react/router';

import { useContributeSection } from './hooks/useContributeSection';

export function ContributeSection() {
	const { translate } = useI18n();
	const {
		actions: { onCtaClick, onSectionView },
	} = useContributeSection();

	return (
		<HeroLayout
			variant="1/2"
			reverseMd
			media={
				<picture>
					<source
						type="image/webp"
						srcSet="https://static.change.org/homepageV2/contribute-image.webp 1x, https://static.change.org/homepageV2/contribute-image@2x.webp 2x"
					/>
					<Image
						alt={translate('corgi.homepage.contribute_section.image.alt_text')}
						src="https://static.change.org/homepageV2/contribute-image.png"
						srcSet="https://static.change.org/homepageV2/contribute-image.png 1x, https://static.change.org/homepageV2/contribute-image@2x.png 2x"
						className="min-h-full object-cover md:min-w-full"
					/>
				</picture>
			}
		>
			<Surface color="surface-neutral" className="p-8 md:p-12">
				<Waypoint onEnter={onSectionView} />
				<div className="inline-flex flex-col space-y-6">
					<Text as="h2" id="contribute-section-title" variant="heading" className="">
						<Translate value="corgi.homepage.contribute_section.title" />
						<br />
						<Translate value="corgi.homepage.contribute_section.subtitle" />
					</Text>
					<Text variant="body" fontWeight="normal">
						<Translate value="corgi.homepage.contribute_section.description" />
					</Text>
					<div>
						<Button data-qa="homepage-contribute-section-cta" onClick={onCtaClick} as={Link} to="/member/join">
							<Translate value="corgi.homepage.contribute_section.cta_btn" />
						</Button>
					</div>
				</div>
			</Surface>
		</HeroLayout>
	);
}
