import type { UtilityContext } from '@change-corgi/core/react/utilityContext';

import { HomepagePageUserDataError, type HomepagePageUserData as PageUserDataReturn } from '../types';

import type { HomepagePageUserDataQuery, HomepagePageUserDataQueryVariables } from './api.graphql';
import { HomepagePageUserData } from './api.graphql';
import { isRelease3Enabled } from './isRelease3Enabled';
import { isV3Enabled } from './isV3Enabled';

export async function getHomepagePageUserData(utilityContext: UtilityContext): Promise<PageUserDataReturn> {
	const {
		gql: { fetch },
		errorReporter,
	} = utilityContext;

	const defaultPageUserData = { showV3: false, showRelease3: false };

	const { data } = await fetch<HomepagePageUserDataQuery, HomepagePageUserDataQueryVariables>({
		query: HomepagePageUserData,
		rejectOnError: true,
	});

	if (!data) {
		void errorReporter.report(
			new HomepagePageUserDataError('homepage_v2_page_user_data_not_found', {
				message: 'Failed to get Feature config data in page user data',
			}),
		);
		return defaultPageUserData;
	}

	try {
		const [showV3, showRelease3] = await Promise.all([
			isV3Enabled(data, utilityContext),
			isRelease3Enabled(data, utilityContext),
		]);

		return { showV3, showRelease3 };
	} catch (e) {
		void errorReporter.report(
			new HomepagePageUserDataError('homepage_v2_page_user_data_not_found', {
				message: 'Failed to get homepage page user experiment data',
				cause: e,
			}),
		);

		return defaultPageUserData;
	}
}
