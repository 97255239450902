import { Surface, Text } from '@change/tomato-core-ui';

import { Translate } from '@change-corgi/core/react/i18n';

export function HowToSection() {
	return (
		<Surface as="section" aria-labelledby="how-to-section-title">
			<div className="grid gap-6">
				<Text as="h2" id="how-to-section-title" variant="heading" fontWeight="normal">
					<Translate value="corgi.homepage.how_to_section.title" />
				</Text>
				<ol sx={{ display: 'grid', gridTemplateColumns: ['1fr', 'repeat(3, 1fr)'], gap: 24 }}>
					<li className="flex" sx={{ flexDirection: ['row', 'column'], gap: [16, 8] }}>
						<Text aria-hidden variant="heading" size="x-large" fontWeight="normal" className="tabular-nums">
							1
						</Text>
						<div className="flex" sx={{ flexDirection: 'column', gap: [16, 24] }}>
							<Text as="h3" variant="body" size="x-large" fontWeight="bold" className="m-0">
								<Translate value="corgi.homepage.how_to_section.steps.step_1.title" />
							</Text>
							<Text variant="body" size="large">
								<Translate value="corgi.homepage.how_to_section.steps.step_1.description" />
							</Text>
						</div>
					</li>
					<li className="flex" sx={{ flexDirection: ['row', 'column'], gap: [16, 8] }}>
						<Text aria-hidden variant="heading" size="x-large" fontWeight="normal" className="tabular-nums">
							2
						</Text>
						<div className="flex" sx={{ flexDirection: 'column', gap: [16, 24] }}>
							<Text variant="body" size="x-large" fontWeight="bold" className="m-0">
								<Translate value="corgi.homepage.how_to_section.steps.step_2.title" />
							</Text>
							<Text variant="body" size="large">
								<Translate value="corgi.homepage.how_to_section.steps.step_2.description" />
							</Text>
						</div>
					</li>
					<li className="flex" sx={{ flexDirection: ['row', 'column'], gap: [16, 8] }}>
						<Text aria-hidden variant="heading" size="x-large" fontWeight="normal" className="tabular-nums">
							3
						</Text>
						<div className="flex" sx={{ flexDirection: 'column', gap: [16, 24] }}>
							<Text variant="body" size="x-large" fontWeight="bold" className="m-0">
								<Translate value="corgi.homepage.how_to_section.steps.step_3.title" />
							</Text>
							<Text variant="body" size="large">
								<Translate value="corgi.homepage.how_to_section.steps.step_3.description" />
							</Text>
						</div>
					</li>
				</ol>
			</div>
		</Surface>
	);
}
