/* eslint-disable no-param-reassign */
import { getRealIndex } from '../getRealIndex';
import { useScrollToStory } from '../useScrollToStory';

export function useArrowClick({
	containerRef,
	storyRefs,
	setCurrentStoryIndex,
	suppressScrollRef,
}: {
	containerRef: React.RefObject<HTMLDivElement>;
	storyRefs: React.MutableRefObject<Array<HTMLDivElement | null>>;
	setCurrentStoryIndex: (index: number) => void;
	suppressScrollRef: React.MutableRefObject<boolean>;
}) {
	const scrollToStory = useScrollToStory(containerRef, storyRefs);

	return (targetIndex: number) => {
		suppressScrollRef.current = true;

		scrollToStory(targetIndex);

		if (targetIndex <= 2 || targetIndex >= 8) {
			const realIndex = getRealIndex(targetIndex);
			setCurrentStoryIndex(realIndex);

			setTimeout(() => {
				scrollToStory(realIndex, { behavior: 'auto' });
				setTimeout(() => {
					suppressScrollRef.current = false;
				}, 50);
			}, 400);
		} else {
			setCurrentStoryIndex(targetIndex);

			setTimeout(() => {
				suppressScrollRef.current = false;
			}, 400);
		}
	};
}
