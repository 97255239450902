import { useMemo } from 'react';

import { Helmet } from 'react-helmet-async';
import { useLocation, useParams } from 'react-router';

import { useI18n } from '@change-corgi/core/react/i18n';
import { useUtilityContext } from '@change-corgi/core/react/utilityContext';

import { HOMEPAGE_L10N_MAP } from 'config/routes-l10n';

import { HrefLangsHomepage } from 'src/app/shared/components/head/containers/HrefLangsHomepage';

import { resolveLocale } from './helpers';

export function PageMeta() {
	const { resolveUrl } = useUtilityContext();
	const { translate } = useI18n();
	const { locale: localeParam } = useParams<{ locale?: string }>();
	const { pathname } = useLocation();

	// routes are case-insensitive, but we want the canonical url
	// to be case-sensitive, so we convert to properly formatted l10n
	const canonicalUrl = useMemo(() => {
		// react-router 6 removed support of regexs - there's an ongoing discussion to re-add them (https://github.com/remix-run/react-router/discussions/9844)
		const locale = localeParam || pathname.split('/')[1];
		if (locale) {
			const validL10n = locale ? HOMEPAGE_L10N_MAP[locale.toLowerCase()] : undefined;

			return validL10n ? resolveUrl(resolveLocale(locale)) : resolveUrl('/');
		}

		return resolveUrl('/');
	}, [localeParam, pathname, resolveUrl]);

	const changeLogoAssetUrl = 'https://static.change.org/favicon/favicon-400x400.png';
	return (
		<>
			<Helmet>
				<link rel="canonical" href={canonicalUrl} />
				<title>{translate('corgi.homepage.meta.title')}</title>
				<meta property="title" content={translate('corgi.homepage.meta.title')} />
				<meta name="description" content={translate('corgi.homepage.meta.description')} />
				<meta property="og:url" content={canonicalUrl} />
				<meta property="og:title" content={translate('corgi.homepage.meta.og.title')} />
				<meta property="og:description" content={translate('corgi.homepage.meta.og.description')} />
				<meta property="og:image" content={changeLogoAssetUrl} />
				<meta property="og:type" content="website" />
				<meta name="twitter:card" content="summary" />
				<meta property="twitter:image" content={changeLogoAssetUrl} />
				<meta property="twitter:image:alt" content={translate('corgi.homepage.meta.title')} />
			</Helmet>
			<HrefLangsHomepage />
		</>
	);
}
