import { useState } from 'react';

import { useHomepagePageData } from 'src/app/pages/homepageV2/pageContext';
import type { HomepagePageData } from 'src/app/pages/homepageV2/pageContext/pageData/api';

type Result = ModelHookResult<
	{ marqueeItems: HomepagePageData['marqueeItems']; isPaused: boolean },
	{ toggleAnimation: () => void }
>;

export function useMarqueeSection(): Result {
	const {
		data: { marqueeItems },
	} = useHomepagePageData();

	const [isPaused, setIsPaused] = useState(false);

	const toggleAnimation = () => {
		setIsPaused((prev) => !prev);
	};

	return {
		data: {
			marqueeItems,
			isPaused,
		},
		actions: {
			toggleAnimation,
		},
	};
}
