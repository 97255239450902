import { translationKey } from '@change-corgi/core/i18n';

export const MARQUEE_ITEMS = [
	{
		id: '1',
		imageUrl: 'https://static.change.org/homepageV2/hero-marquee-image-2@2x.png',
		srcSet:
			'https://static.change.org/homepageV2/hero-marquee-image-2@1x.png 1x, https://static.change.org/homepageV2/hero-marquee-image-2@2x.png 2x',
		srcSetWebp:
			'https://static.change.org/homepageV2/hero-marquee-image-2@1x.webp 1x, https://static.change.org/homepageV2/hero-marquee-image-2@2x.webp 2x',
		description: translationKey('corgi.homepage.marquee.item1.description'),
		altText: translationKey('corgi.homepage.marquee.item1.alt_text'),
	},
	{
		id: '2',
		imageUrl: 'https://static.change.org/homepageV2/hero-marquee-image-1@2x.png',
		srcSet:
			'https://static.change.org/homepageV2/hero-marquee-image-1@1x.png 1x, https://static.change.org/homepageV2/hero-marquee-image-1@2x.png 2x',
		srcSetWebp:
			'https://static.change.org/homepageV2/hero-marquee-image-1@1x.webp 1x, https://static.change.org/homepageV2/hero-marquee-image-1@2x.webp 2x',
		description: translationKey('corgi.homepage.marquee.item2.description'),
		altText: translationKey('corgi.homepage.marquee.item2.alt_text'),
	},
	{
		id: '3',
		imageUrl: 'https://static.change.org/homepageV2/hero-marquee-image-3@2x.png',
		srcSet:
			'https://static.change.org/homepageV2/hero-marquee-image-3@1x.png 1x, https://static.change.org/homepageV2/hero-marquee-image-3@2x.png 2x',
		srcSetWebp:
			'https://static.change.org/homepageV2/hero-marquee-image-3@1x.webp 1x, https://static.change.org/homepageV2/hero-marquee-image-3@2x.webp 2x',
		description: translationKey('corgi.homepage.marquee.item3.description'),
		altText: translationKey('corgi.homepage.marquee.item3.alt_text'),
	},
	{
		id: '4',
		imageUrl: 'https://static.change.org/homepageV2/hero-marquee-image-4@2x.png',
		srcSet:
			'https://static.change.org/homepageV2/hero-marquee-image-4@1x.png 1x, https://static.change.org/homepageV2/hero-marquee-image-4@2x.png 2x',
		srcSetWebp:
			'https://static.change.org/homepageV2/hero-marquee-image-4@1x.webp 1x, https://static.change.org/homepageV2/hero-marquee-image-4@2x.webp 2x',
		description: translationKey('corgi.homepage.marquee.item4.description'),
		altText: translationKey('corgi.homepage.marquee.item4.alt_text'),
	},
	{
		id: '5',
		imageUrl: 'https://static.change.org/homepageV2/hero-marquee-image-5@2x.png',
		srcSet:
			'https://static.change.org/homepageV2/hero-marquee-image-5@1x.png 1x, https://static.change.org/homepageV2/hero-marquee-image-5@2x.png 2x',
		srcSetWebp:
			'https://static.change.org/homepageV2/hero-marquee-image-5@1x.webp 1x, https://static.change.org/homepageV2/hero-marquee-image-5@2x.webp 2x',
		description: translationKey('corgi.homepage.marquee.item5.description'),
		altText: translationKey('corgi.homepage.marquee.item5.alt_text'),
	},
	{
		id: '6',
		imageUrl: 'https://static.change.org/homepageV2/hero-marquee-image-6@2x.png',
		srcSet:
			'https://static.change.org/homepageV2/hero-marquee-image-6@1x.png 1x, https://static.change.org/homepageV2/hero-marquee-image-6@2x.png 2x',
		srcSetWebp:
			'https://static.change.org/homepageV2/hero-marquee-image-6@1x.webp 1x, https://static.change.org/homepageV2/hero-marquee-image-6@2x.webp 2x',
		description: translationKey('corgi.homepage.marquee.item6.description'),
		altText: translationKey('corgi.homepage.marquee.item6.alt_text'),
	},
];
