import type { UtilityContext } from '@change-corgi/core/react/utilityContext';

import type { LocalSectionHomepageDataOldQuery, LocalSectionHomepageDataOldQueryVariables } from './api.graphql';
import { LocalSectionHomepageDataOld } from './api.graphql';

type LocalSectionLocation = NonNullable<LocalSectionHomepageDataOldQuery['localSectionLocation']>;
type LocalData = {
	signaturesCount: LocalSectionLocation['totalSignatureCount'];
	signaturesCountLocalized: LocalSectionLocation['totalSignatureCountLocalized'];
	petitionsCount: LocalSectionLocation['totalPetitionCount'];
	petitionsCountLocalized: LocalSectionLocation['totalPetitionCountLocalized'];
	decisionMakersCount: LocalSectionLocation['decisionMakersConnection']['totalCount'];
	decisionMakersCountLocalized: LocalSectionLocation['decisionMakersConnection']['totalCountLocalized'];
};

export class LocalSectionDataMissingError extends Error {
	constructor() {
		super('No data returned from local section homepage query');
	}
}

export async function getLocalSectionData(
	slug: string,
	locale: string,
	{ gql: { fetch }, errorReporter: { report } }: UtilityContext,
): Promise<LocalData> {
	const { data } = await fetch<LocalSectionHomepageDataOldQuery, LocalSectionHomepageDataOldQueryVariables>({
		query: LocalSectionHomepageDataOld,
		variables: {
			slug,
			locale,
			first: 1,
		},
		rejectOnError: true,
	});

	if (!data || !data.localSectionLocation) {
		const error = new LocalSectionDataMissingError();
		void report({ error, params: { slug, locale } });
		throw error;
	}

	return {
		signaturesCount: data.localSectionLocation.totalSignatureCount,
		petitionsCount: data.localSectionLocation.totalPetitionCount,
		decisionMakersCount: data.localSectionLocation.decisionMakersConnection.totalCount,
		signaturesCountLocalized: data.localSectionLocation.totalSignatureCountLocalized,
		petitionsCountLocalized: data.localSectionLocation.totalPetitionCountLocalized,
		decisionMakersCountLocalized: data.localSectionLocation.decisionMakersConnection.totalCountLocalized,
	};
}
