import type { ComponentType, JSX, PropsWithChildren } from 'react';

import { TrackingContext } from '@change-corgi/core/react/tracking';

import type { ErrorStateDetails } from 'src/app/shared/utils/async';
import { isError, isLoaded } from 'src/app/shared/utils/async';

import type {
	HomepagePageCombinedState,
	HomepagePagePrefetchedContext,
	HomepagePagePrefetchedUserContext,
} from './hook';
import { usePageContext } from './hook';
import { HomepagePageDataContextProvider } from './pageData';
import { HomepagePageFcmContextProvider } from './pageFcm';
import { HomepagePageFcmExperimentsContextProvider } from './pageFcmExperiments';
import { HomepagePageUserDataContextProvider } from './pageUserData';

export type ErrorHandlerProps = { context: ErrorStateDetails<HomepagePageCombinedState> };

type Props = {
	prefetchedData: HomepagePagePrefetchedContext | undefined;
	prefetchedUserData: HomepagePagePrefetchedUserContext | undefined;
	errorHandler: ComponentType<ErrorHandlerProps>;
	loadingFallback: JSX.Element;
};

export function HomepagePageContextProvider({
	children,
	prefetchedData,
	prefetchedUserData,
	errorHandler: ErrorHandler,
	loadingFallback,
}: PropsWithChildren<Props>): JSX.Element | null {
	const pageContextState = usePageContext(prefetchedUserData, prefetchedData);

	if (isError(pageContextState)) {
		const { status, ...rest } = pageContextState;
		return <ErrorHandler context={rest} />;
	}
	if (!isLoaded(pageContextState)) return loadingFallback;

	const { pageData, pageUserData, pageFcm, pageFcmExperiments } = pageContextState;

	return (
		<HomepagePageDataContextProvider pageData={pageData}>
			<HomepagePageUserDataContextProvider pageUserData={pageUserData}>
				<HomepagePageFcmContextProvider pageFcm={pageFcm}>
					<HomepagePageFcmExperimentsContextProvider pageFcmExperiments={pageFcmExperiments}>
						<TrackingContext properties={{ version: pageUserData.data.showV3 ? 'v3' : 'v2' }}>
							{children}
						</TrackingContext>
					</HomepagePageFcmExperimentsContextProvider>
				</HomepagePageFcmContextProvider>
			</HomepagePageUserDataContextProvider>
		</HomepagePageDataContextProvider>
	);
}
