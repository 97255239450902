import { useEffect, useRef, useState } from 'react';

import type { TopStories } from '../../../shared/hooks/useTopImpactStories';
import { useTopImpactStories } from '../../../shared/hooks/useTopImpactStories';
import { useArrowClick } from '../useArrowClick';
import { useSwipeOrScroll } from '../useSwipeOrScroll';

type Result = ModelHookResult<
	{
		carouselStories: Array<
			Omit<TopStories, 'top' | 'transform'> & {
				tabIndex: number;
				ariaHidden: boolean;
			}
		>;
		currentStoryIndex: number;
		containerRef: React.MutableRefObject<HTMLDivElement | null>;
		storyRefs: React.MutableRefObject<Array<HTMLDivElement | null>>;
	},
	{
		onLeftClick: () => void;
		onRightClick: () => void;
		trackStoryClick: (url: string) => void;
		genDataTestId: (key: number, component: string) => string;
		onStoryFocus: (targetIndex: number) => void;
		onScroll: () => void;
	}
>;

const getCarouselStories = (topStories: TopStories[]) => {
	// Implementing carousel looping with clones.
	// We need 3 clones on either end to allow smooth centered scrolling.
	// At the `md` breakpoint, we can see 2 extra items on each side of the centered one.
	// Without a 3rd clone, scrolling to the 5th item would leave an empty gap at the end.
	return [...topStories.slice(-3), ...topStories, ...topStories.slice(0, 3)].map((story, index) => ({
		...story,
		key: index + 1,
		tabIndex: index <= 2 || index >= 8 ? -1 : 0,
		ariaHidden: index <= 2 || index >= 8,
	}));
};

export function useTopImpactStoriesMobile(): Result {
	const {
		data: { topStories },
		actions: { genDataTestId, trackStoryClick },
	} = useTopImpactStories();

	const [currentStoryIndex, setCurrentStoryIndex] = useState(3);

	const containerRef = useRef<HTMLDivElement | null>(null);
	const storyRefs = useRef<Array<HTMLDivElement | null>>([]);

	const suppressScrollRef = useRef(false);

	const handleArrowClick = useArrowClick({
		containerRef,
		storyRefs,
		setCurrentStoryIndex,
		suppressScrollRef,
	});

	const handleSwipeOrScroll = useSwipeOrScroll({
		containerRef,
		storyRefs,
		setCurrentStoryIndex,
		suppressScrollRef,
	});

	useEffect(() => {
		handleArrowClick(3);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return {
		data: {
			currentStoryIndex,
			carouselStories: getCarouselStories(topStories),
			containerRef,
			storyRefs,
		},
		actions: {
			onLeftClick: () => handleArrowClick(currentStoryIndex - 1),
			onRightClick: () => handleArrowClick(currentStoryIndex + 1),
			trackStoryClick,
			genDataTestId,
			onStoryFocus: (targetIndex: number) => handleArrowClick(targetIndex),
			onScroll: handleSwipeOrScroll,
		},
	};
}
