import type { UtilityContext } from '@change-corgi/core/react/utilityContext';
import { FCM_CAPYBARA_HOMEPAGE_V3 } from '@change-corgi/fcm/configs';

import { HomepagePageUserDataError } from '../types';

import { type HomepagePageUserDataQuery } from './api.graphql';

const HERO_V3_EXPERIMENT_NAME = FCM_CAPYBARA_HOMEPAGE_V3.name;

export async function isV3Enabled(userPageData: HomepagePageUserDataQuery, utilityContext: UtilityContext) {
	const { errorReporter, experiments } = utilityContext;

	if (!userPageData.featureConfigs.homepageV3?.enabled) return false;

	try {
		const experiment = experiments.get(HERO_V3_EXPERIMENT_NAME);
		await experiment.treat();
		return !experiment.variation.endsWith('control');
	} catch (e) {
		void errorReporter.report(
			new HomepagePageUserDataError('homepage_v2_page_user_data_not_found', {
				message: `Failed to treat user in experiment: ${HERO_V3_EXPERIMENT_NAME}`,
			}),
		);
		return false;
	}
}
