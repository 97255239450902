import { Text } from '@change/tomato-core-ui';

import { TranslateHtml } from '@change-corgi/core/react/i18n';

export const TitleAndTagline: React.FC = () => {
	return (
		<>
			<div className="text-center">
				<Text variant="display" as="h1" className="m-0 text-6xl font-extrabold md:text-7xl">
					<TranslateHtml value="corgi.homepage.hero.title" />
					<span className="font-[serif] text-content-highlight">.</span>
				</Text>
			</div>

			<Text
				as="p"
				variant="heading"
				className="mx-auto mb-4 mt-4 max-w-[400px] text-center text-2xl font-normal xl:max-w-[500px]"
			>
				{/* delaying investment in hardcoded tagline till finalized */}
				<TranslateHtml value="corgi.homepage.hero.tagline" replacements={{ userCount: '500,000,000+' }} />
			</Text>
		</>
	);
};
