/* eslint-disable @typescript-eslint/naming-convention */
import { useMemo } from 'react';

import type { Organization } from 'schema-dts';

import { jsonLd } from 'src/app/shared/utils/jsonLd';

type Result = ModelHookResult<{
	schemaJson: string;
}>;

export function usePageOrganizationSchema(): Result {
	const organizationSchema = useMemo(
		() =>
			jsonLd<Organization>({
				'@context': 'https://schema.org',
				'@type': 'Organization',
				image: 'https://static.change.org/favicons/favicon-400x400.png',
				url: 'https://www.change.org/',
				sameAs: [
					'https://www.facebook.com/change.org',
					'https://x.com/Change',
					'https://www.instagram.com/changedotorg/',
					'https://www.linkedin.com/company/change-org/',
				],
				logo: 'https://static.change.org/favicons/favicon-114x114.png',
				name: 'Change.org',
				description:
					"Change.org is the world's platform for change. On Change.org, people everywhere are starting campaigns and petitions, mobilizing supporters, and working with decision makers to drive solutions.",
				email: 'support@change.org',
				contactPoint: {
					'@type': 'ContactPoint',
					url: 'https://help.change.org/contact-us',
				},
			}),
		[],
	);

	return {
		data: {
			schemaJson: organizationSchema,
		},
	};
}
