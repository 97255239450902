import { withI18nPlaceholders } from '@change-corgi/core/react/i18n';

import I18N_PLACEHOLDERS from 'config/i18n/placeholders/homepageV3/homepage.yml';

import { EasyBrief } from './components/EasyBrief';
import { TipsAndGuides } from './components/TipsAndGuides';

export const HowToSectionV3: React.FC = withI18nPlaceholders(I18N_PLACEHOLDERS, () => {
	return (
		<>
			<EasyBrief />
			<TipsAndGuides />
		</>
	);
});
