import type {
	PrefetchContext,
	PrefetchedDataProps,
	PrefetchedUserDataProps,
	PrefetchUserContext,
} from 'src/shared/prefetch';

import { useCountryCode, useLocale } from 'src/app/shared/hooks/l10n';
import type { MergedState } from 'src/app/shared/hooks/state';
import { useMergedStates } from 'src/app/shared/hooks/state';

import { prefetchHomepagePageDataFromApi, useHomepagePageDataFromApi } from './pageData';
import { prefetchHomepagePageFcm, useAsyncHomepagePageFcm } from './pageFcm';
import { prefetchHomepagePageFcmExperiments, useAsyncHomepagePageFcmExperiments } from './pageFcmExperiments';
import { prefetchHomepagePageUserDataFromApi, useHomepagePageUserDataFromApi } from './pageUserData';

export type HomepagePagePrefetchedContext = Readonly<{
	pageData: Parameters<typeof useHomepagePageDataFromApi>[0];
}>;

export type HomepagePagePrefetchedUserContext = NonNullable<Parameters<typeof useHomepagePageUserDataFromApi>[0]>;

export type HomepagePageCombinedState = MergedState<
	ReturnType<typeof useHomepagePageDataFromApi>,
	ReturnType<typeof useHomepagePageUserDataFromApi>,
	ReturnType<typeof useAsyncHomepagePageFcmExperiments>,
	ReturnType<typeof useAsyncHomepagePageFcm>
>;

export function usePageContext(
	prefetchedUserData?: HomepagePagePrefetchedUserContext,
	prefetchedData?: HomepagePagePrefetchedContext,
): HomepagePageCombinedState {
	const locale = useLocale();
	const countryCode = useCountryCode();
	return useMergedStates(
		useHomepagePageDataFromApi(prefetchedData?.pageData, { locale, countryCode }),
		useHomepagePageUserDataFromApi(prefetchedUserData),
		useAsyncHomepagePageFcmExperiments(),
		useAsyncHomepagePageFcm(),
	);
}

export async function prefetchPageContext(context: PrefetchContext): Promise<HomepagePagePrefetchedContext> {
	const [pageData] = await Promise.all([
		prefetchHomepagePageDataFromApi(context, {
			countryCode: context.l10n.countryCode,
			locale: context.l10n.locale,
		}),
		prefetchHomepagePageFcmExperiments(context),
		prefetchHomepagePageFcm(context),
	]);

	return { pageData };
}

export async function prefetchPageUserContext(
	context: PrefetchUserContext,
): Promise<HomepagePagePrefetchedUserContext> {
	return prefetchHomepagePageUserDataFromApi(context);
}

export type HomepagePagePrefetchedDataProps = PrefetchedDataProps<HomepagePagePrefetchedContext> &
	PrefetchedUserDataProps<HomepagePagePrefetchedUserContext>;
