import { useTracking } from '@change-corgi/core/react/tracking';

import { useHomepagePageData } from 'src/app/pages/homepageV2/pageContext';

type Petition = {
	id: string;
	ask: string;
	description: string;
	slug: string;
	signatureCount: number;
	signatureCountLocalized: string;
	photoUrl?: string;
	userName: string;
	userPhotoUrl?: string;
	isVictory: boolean;
	relevantLocationLocalizedName: string;
	tagsConnection: readonly Tag[];
};

type Tag = {
	readonly id: string;
	readonly name: string;
	readonly slug: string;
};

type Topic = {
	name: string;
	slug: string;
};

type PetitionCardTrackingParams = {
	ask: string;
	petitionId: string;
	signatureCount: number;
	tags: readonly Tag[];
	cardPosition: number;
	location: string;
};

type Result = ModelHookResult<
	{
		shouldShowSection: boolean;
		shouldShowTopics: boolean;
		petitions: Petition[];
		topics: Topic[];
	},
	{
		onTopicClick: (topicName: string) => void;
		onPetitionCardClick: (trackingParams: PetitionCardTrackingParams) => void;
	}
>;

const DEFAULT_AVATAR = 'https://static.change.org/profile-img/default-user-profile.svg';
const DEFAULT_PETITION_IMAGE = 'https://static.change.org/images/default-petition.gif';

// eslint-disable-next-line max-lines-per-function
export function useTrendingSection(): Result {
	const track = useTracking();
	const {
		data: { trendingPetitions, trendingTopics },
	} = useHomepagePageData();

	const petitions = trendingPetitions
		.filter((petition) => petition.node !== null)
		// eslint-disable-next-line complexity
		.map((petition) => {
			const userPhoto = petition.node.organization
				? petition.node.organization.photo?.organizationLarge
				: petition.node.user.photo?.userLarge;
			const userName = petition.node.organization ? petition.node.organization?.name : petition.node.user.displayName;

			return {
				id: petition.node.id,
				ask: petition.node.displayTitle,
				description: petition.node.description,
				slug: `/p/${encodeURIComponent(petition.node.slug)}`,
				signatureCount: petition.node.signatureState.signatureCount.displayed,
				signatureCountLocalized: petition.node.signatureState.signatureCount.displayedLocalized,
				photoUrl: petition.node.photo?.petitionLarge.processing
					? DEFAULT_PETITION_IMAGE
					: petition.node.photo?.petitionLarge.url,
				isVictory: petition.node.isVerifiedVictory,
				userName,
				userPhotoUrl: userPhoto?.processing ? DEFAULT_AVATAR : userPhoto?.url,

				relevantLocationLocalizedName: petition.node.relevantLocationLocalizedName || '',

				tagsConnection: petition.node.tagsConnection?.nodes ?? [],
			};
		});

	const topics = trendingTopics
		? trendingTopics
				.filter((topic): topic is Topic => topic.slug !== null && topic.name !== null)
				.map((topic) => ({
					name: topic.name,
					slug: `/t/${encodeURIComponent(topic.slug)}`,
				}))
		: [];
	return {
		actions: {
			onTopicClick: (topicName: string) => {
				void track('homepage_topic_page_click', { topic_name: topicName });
			},
			onPetitionCardClick: (trackingParams: PetitionCardTrackingParams) =>
				// eslint-disable-next-line no-void
				void track('homepage_featured_petition_click', {
					petition_title: trackingParams.ask,
					petition_id: trackingParams.petitionId,
					signatures: trackingParams.signatureCount,
					tags: trackingParams.tags.map((tag) => tag.name),
					card_position: trackingParams.cardPosition,
					location: trackingParams.location,
				}),
		},
		data: {
			shouldShowTopics: topics.length > 0,
			shouldShowSection: petitions && petitions.length > 0,
			petitions,
			topics,
		},
	};
}
