import type { UtilityContext } from '@change-corgi/core/react/utilityContext';

import { HomepagePageUserDataError } from '../types';

import type { HomepagePageUserDataQuery } from './api.graphql';

const release3ExperimentName = 'starfish_homepage_v2_release_3';

export async function isRelease3Enabled(userPageData: HomepagePageUserDataQuery, utilityContext: UtilityContext) {
	const { errorReporter, experiments } = utilityContext;
	const { isEnabled, experimentName } = userPageData.featureConfigs.homepageV2;

	if (!isEnabled || experimentName !== release3ExperimentName) return false;

	try {
		const experiment = experiments.get(experimentName);
		await experiment.treat();
		return !experiment.variation.endsWith('control');
	} catch (e) {
		void errorReporter.report(
			new HomepagePageUserDataError('homepage_v2_page_user_data_not_found', {
				message: `Failed to treat user in experiment: ${experimentName}`,
			}),
		);
		return false;
	}
}
