/* istanbul ignore file REMOVE THIS WHEN I18NPLACEHOLDERS ARE REMOVED */
import type { JSX } from 'react';

import { withI18nPlaceholders } from '@change-corgi/core/react/i18n';
import { Loader } from '@change-corgi/design-system/components/progressiveDisclosure';

import I18N_PLACEHOLDERS from 'config/i18n/placeholders/homepageV3/homepage.yml';

import { withPrefetchedData } from 'src/shared/prefetch';

import type { HomepagePagePrefetchedDataProps } from 'src/app/pages/homepageV2/pageContext';
import {
	HomepagePageContextProvider,
	prefetchPageContext,
	prefetchPageUserContext,
} from 'src/app/pages/homepageV2/pageContext';

import { Layout } from '../layout';

import { HomepageErrorHandler } from './ErrorHandler';

export const Homepage = withI18nPlaceholders(
	I18N_PLACEHOLDERS,
	withPrefetchedData(
		function Homepage({ prefetchedData, prefetchedUserData }: HomepagePagePrefetchedDataProps): JSX.Element {
			return (
				<HomepagePageContextProvider
					prefetchedData={prefetchedData}
					prefetchedUserData={prefetchedUserData}
					errorHandler={HomepageErrorHandler}
					loadingFallback={<Loader size="m" my={256} mx="auto" />}
				>
					<Layout />
				</HomepagePageContextProvider>
			);
		},
		{
			prefetchName: __MODULE_HASH__,
			prefetchData: async (context) => prefetchPageContext(context),
			prefetchUserData: async (context) => prefetchPageUserContext(context),
		},
	),
);
