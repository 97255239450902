import React from 'react';

import { Text } from '@change/tomato-core-ui';

type Props = {
	labelTitle: string;
	labelText: string;
};

export const Badge: React.FC<Props> = ({ labelTitle, labelText }: Props) => {
	return (
		<div className="border-surface-neutral -mt-7 inline-block min-w-[8rem] whitespace-nowrap rounded-full border-2 bg-surface-accent px-4 py-2 text-center text-content-inverse">
			<Text as="p" fontWeight="bold" size="small" className="-mb-1">
				{labelTitle}
			</Text>
			<Text size="small">{labelText}</Text>
		</div>
	);
};
