import { Button } from '@change/tomato-core-ui';

import { Translate } from '@change-corgi/core/react/i18n';
import { Link as RouterLink } from '@change-corgi/core/react/router';

import { useSapCtas } from '../../../../shared/hooks/useSapCtas';

export const SapCtas: React.FC = () => {
	const { actions, data } = useSapCtas();

	return (
		<div className="flex flex-col items-center justify-center gap-4 lg:flex-row">
			<Button
				as={RouterLink}
				data-testid="homepage-hero-primary-cta"
				data-qa="homepage-hero-primary-cta"
				to={data.primaryCtaUrl}
				variant="primary"
				size="small"
				className="max-w-full self-stretch lg:self-auto"
				onClick={actions.onPrimaryCtaClick}
			>
				<Translate value="corgi.homepage.hero.primary_button_text" />
			</Button>
			<Button
				as={RouterLink}
				data-testid="homepage-hero-secondary-cta"
				data-qa="homepage-hero-secondary-cta"
				to={data.secondaryCtaUrl}
				variant="secondary"
				size="small"
				className="max-w-full self-stretch lg:self-auto"
				onClick={actions.onSecondaryCtaClick}
			>
				<Translate value="corgi.homepage.hero.secondary_button_text" />
			</Button>
		</div>
	);
};
