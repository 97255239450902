import startCase from 'lodash/startCase';

import { Chip, PetitionCard, Text } from '@change/tomato-core-ui';
import { ArrowRightIcon } from '@change/tomato-icons';

import { Translate, useI18n } from '@change-corgi/core/react/i18n';

import { useTrendingSection } from './hooks/useTrendingSection';

export function TrendingSection() {
	const {
		actions,
		data: { shouldShowSection, shouldShowTopics, petitions, topics },
	} = useTrendingSection();

	const { translate, translatePlural } = useI18n();

	if (!shouldShowSection) return null;

	return (
		<section className="flex flex-col gap-6" aria-labelledby="trending-section-title" data-qa="home-discover-feed">
			<Text
				className="m-0"
				as="h2"
				id="trending-section-title"
				data-qa="trending-section-title"
				variant="heading"
				fontWeight="normal"
			>
				<Translate value="corgi.homepage.trending_section.title" />
			</Text>
			<Text variant="body" size="large">
				<Translate value="corgi.homepage.trending_section.description" />
			</Text>
			<div className="flex flex-wrap items-center gap-2" data-qa="featured-topics">
				{shouldShowTopics &&
					topics.map((topic) => {
						return (
							<Chip
								data-testid="topic-chip"
								data-qa="topic-chip"
								key={topic.name}
								as="a"
								href={topic.slug}
								active={false}
								className="class-names"
								disabled={false}
								size="large"
								trailingIcon={<ArrowRightIcon />}
								onClick={() => actions.onTopicClick(topic.name)}
							>
								{startCase(topic.name)}
							</Chip>
						);
					})}
			</div>
			<div className="flex flex-col items-center gap-6">
				<div className="grid gap-6 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
					{petitions.map((petition, index) => (
						<PetitionCard
							key={petition.id}
							className="petition-card"
							imageUrl={petition.photoUrl}
							location={petition.relevantLocationLocalizedName}
							petitionId={petition.id}
							petitionLinkOnClick={() =>
								actions.onPetitionCardClick({
									ask: petition.ask,
									petitionId: petition.id,
									signatureCount: petition.signatureCount,
									tags: petition.tagsConnection,
									cardPosition: index + 1,
									location: petition.relevantLocationLocalizedName,
								})
							}
							isVictory={petition.isVictory}
							petitionLinkUrl={petition.slug}
							supporterCount={petition.signatureCountLocalized}
							title={petition.ask}
							locales={{
								victory: translate('corgi.components.petition_card.victory'),
								supporters: translatePlural('corgi.components.petition_card.signatures', petition.signatureCount, {
									countLocalized: petition.signatureCountLocalized,
								}),
								signPetition: translate('corgi.components.petition_card.sign_button_label'),
								// not using promotedBy & mediaMentions
							}}
						/>
					))}
				</div>
			</div>
		</section>
	);
}
