import type { TranslationKey } from '@change-corgi/core/i18n';
import { translationKey } from '@change-corgi/core/i18n';

type Brief = {
	key: string;
	titleKey: TranslationKey;
	descriptionKey: TranslationKey;
};
type Result = ModelHookResult<{
	briefs: Brief[];
}>;

export function useEasyBrief(): Result {
	return {
		data: {
			briefs: [
				{
					key: 'brief-1',
					titleKey: translationKey('corgi.homepage.how_to.easy_brief.brief_1.title'),
					descriptionKey: translationKey('corgi.homepage.how_to.easy_brief.brief_1.description'),
				},
				{
					key: 'brief-2',
					titleKey: translationKey('corgi.homepage.how_to.easy_brief.brief_2.title'),
					descriptionKey: translationKey('corgi.homepage.how_to.easy_brief.brief_2.description'),
				},
				{
					key: 'brief-3',
					titleKey: translationKey('corgi.homepage.how_to.easy_brief.brief_3.title'),
					descriptionKey: translationKey('corgi.homepage.how_to.easy_brief.brief_3.description'),
				},
			],
		},
	};
}
