import { Image, Surface } from '@change/tomato-core-ui';

import { withI18nPlaceholders } from '@change-corgi/core/react/i18n';

import I18N_PLACEHOLDERS from 'config/i18n/placeholders/homepageV3/homepage.yml';

import { useHomepagePageData } from 'src/app/pages/homepageV2/pageContext';

import { SapCtas } from './components/SapCtas';
import { TitleAndTagline } from './components/TitleAndTagline';
import { TopImpactStoriesDesktop } from './components/TopImpactStoriesDesktop';
import { TopImpactStoriesMobile } from './components/TopImpactStoriesMobile';

function BgImagePreload({ fetchPriority: fetchpriority }: { fetchPriority: string }) {
	return (
		<Image
			src="https://static.change.org/homepageV3/background-globe.svg"
			alt="Globe"
			{...(fetchpriority ? { fetchpriority } : {})}
			className="absolute left-1/2 top-[55%] z-0 h-full w-full -translate-x-1/2 -translate-y-[80%] transform bg-transparent object-cover md:object-contain xl:-translate-y-[95%] xl:scale-125"
		/>
	);
}

export const HeroSectionV3: React.FC = withI18nPlaceholders(I18N_PLACEHOLDERS, () => {
	const {
		data: { v3TopImpactStories },
	} = useHomepagePageData();

	return (
		<Surface
			as="section"
			color="surface-neutral"
			data-testid="homepage-hero-section"
			className={`relative overflow-hidden ${v3TopImpactStories.length > 0 ? 'h-[750px] md:h-[800px] lg:h-[740px]' : ''} to-surface-neutral bg-gradient-to-b from-white`}
		>
			<BgImagePreload fetchPriority="high" />
			<div className="container relative z-10 py-4 md:py-8">
				<TitleAndTagline />
				<SapCtas />
				{v3TopImpactStories.length > 0 && <TopImpactStoriesDesktop />}
			</div>
			{v3TopImpactStories.length > 0 && <TopImpactStoriesMobile />}
		</Surface>
	);
});
