import qs from 'qs';

import { useTracking } from '@change-corgi/core/react/tracking';

import { useHomepagePageData } from 'src/app/pages/homepageV2/pageContext';
import { generateAiSapParam } from 'src/app/shared/utils/generateAiSapParam';

type Result = ModelHookResult<
	{
		primaryCtaUrl: string;
		secondaryCtaUrl: string;
		totalUserCount: { total: number; totalLocalized: string };
	},
	{
		onPrimaryCtaClick: () => void;
		onSecondaryCtaClick: () => void;
	}
>;

export function useSapCtas(): Result {
	const track = useTracking();

	const queryParam = generateAiSapParam();
	const primaryCtaUrl = '/start-a-petition?source_location=homepage_primary_button';
	const secondaryCtaUrl = `/start-a-petition?${qs.stringify({ state: queryParam, source_location: 'homepage_secondary_button' })}`;
	const {
		data: { totalUserCount },
	} = useHomepagePageData();

	return {
		actions: {
			onPrimaryCtaClick: () => {
				void track('homepage_SAP_click', {
					cta: 'primary',
				});
			},
			onSecondaryCtaClick: () => {
				void track('homepage_SAP_click', {
					cta: 'secondary',
				});
			},
		},
		data: {
			primaryCtaUrl,
			secondaryCtaUrl,
			totalUserCount,
		},
	};
}
