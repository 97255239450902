import { useTracking } from '@change-corgi/core/react/tracking';

type Result = ModelHookResult<
	undefined,
	{
		onCtaClick: () => void;
		onSectionView: () => void;
	}
>;

export function useContributeSection(): Result {
	const track = useTracking();
	return {
		actions: {
			onCtaClick: () => {
				void track('homepage_membership_page_click');
			},
			onSectionView: () => {
				void track('homepage_membership_ask_view');
			},
		},
	};
}
