import { createFcmExperimentsContext } from 'src/app/shared/contexts/experiment';
import type { LoadedStateData } from 'src/app/shared/utils/async';

export const {
	FcmExperimentsContext: HomepagePageFcmExperimentsContext,
	FcmExperimentsProvider: HomepagePageFcmExperimentsContextProvider,
	useFcmExperiments: useHomepagePageFcmExperiments,
	useAsyncFcmExperiments: useAsyncHomepagePageFcmExperiments,
	prefetchFcmExperiments: prefetchHomepagePageFcmExperiments,
} = createFcmExperimentsContext({
	name: 'HomepagePageFcmExperimentsContext',
	dataProperty: 'pageFcmExperiments',
	configs: {},
	prefetch: true,
});

export type HomepagePageFcmExperimentsState = ReturnType<typeof useAsyncHomepagePageFcmExperiments>;
export type HomepagePageFcmExperiments = LoadedStateData<HomepagePageFcmExperimentsState>;
