import { Image, Text } from '@change/tomato-core-ui';
import { PauseCircleFilledIcon, PlayCircleFilledIcon } from '@change/tomato-icons';

import { Translate, useI18n } from '@change-corgi/core/react/i18n';

import { useMarqueeSection } from './hooks/useMarqueeSection';

export const MarqueeSection: React.FC = () => {
	const {
		data: { marqueeItems, isPaused },
		actions: { toggleAnimation },
	} = useMarqueeSection();

	const { translate } = useI18n();

	return (
		<div>
			<div className="flex flex-nowrap overflow-x-hidden">
				<div
					className="flex flex-shrink-0 animate-marquee data-[paused='true']:[animation-play-state:paused]"
					data-paused={isPaused}
				>
					{marqueeItems.map((item) => (
						<div key={item.id} className="relative mx-3 max-w-[400px] overflow-hidden rounded-small">
							<picture>
								<source srcSet={item.srcSetWebp} type="image/webp" />
								<Image
									src={item.imageUrl}
									srcSet={item.srcSet}
									alt={translate(item.altText)}
									className="size-full object-cover"
								/>
							</picture>

							<div className="absolute inset-0 bg-gradient-to-t from-[rgba(0,0,0,80%)] via-[rgba(0,0,0,80%)] via-35% to-transparent to-50%"></div>
							<div className="absolute inset-0 flex flex-col justify-end gap-4 p-7">
								<Text size="small" fontWeight="bold" as="p" className="text-pretty text-content-inverse">
									<Translate value={item.description} />
								</Text>
							</div>
						</div>
					))}
				</div>

				<div
					className="flex flex-shrink-0 animate-marquee data-[paused='true']:[animation-play-state:paused]"
					data-paused={isPaused}
					aria-hidden="true"
				>
					{marqueeItems.map((item) => (
						<div key={item.id} className="relative mx-3 max-w-[400px] overflow-hidden rounded-small">
							<picture>
								<source srcSet={item.srcSetWebp} type="image/webp" />
								<Image
									src={item.imageUrl}
									srcSet={item.srcSet}
									alt={translate(item.altText)}
									className="size-full object-cover"
								/>
							</picture>

							<div className="absolute inset-0 bg-gradient-to-t from-[rgba(0,0,0,80%)] via-[rgba(0,0,0,80%)] via-35% to-transparent to-50%"></div>
							<div className="absolute inset-0 flex flex-col justify-end gap-4 p-7">
								<Text size="small" fontWeight="bold" as="p" className="text-pretty text-content-inverse">
									<Translate value={item.description} />
								</Text>
							</div>
						</div>
					))}
				</div>
			</div>

			<div className="container flex justify-end py-6">
				<button
					type="button"
					onClick={toggleAnimation}
					aria-label={
						isPaused
							? translate('corgi.homepage.marquee.play_button_aria_label')
							: translate('corgi.homepage.marquee.pause_button_aria_label')
					}
					className="text-content-tertiary-tertiary"
					data-qa="homepage-marquee-toggle"
				>
					{isPaused ? <PlayCircleFilledIcon size="large" /> : <PauseCircleFilledIcon size="large" />}
				</button>
			</div>
		</div>
	);
};
