import { useMemo } from 'react';

import { useSession } from 'src/app/shared/hooks/session';

type UserLocation = { shortName: string; name: string; slug: string };
type Result = { userLocation: UserLocation | null };

export function useUserLocation(): Result {
	const session = useSession();

	const hasFullUserLocation = !!(session.user && (session.user.city || session.user.stateCode));
	const hasFullUserGeoipLocation = !!(session.geoipData && (session.geoipData.city || session.geoipData.region));
	// get user's location
	const userLocationObject = useMemo(() => {
		if (hasFullUserLocation) {
			const { user } = session;
			return { city: user.city, stateCode: user.stateCode, countryCode: user.country?.countryCode };
		}

		if (hasFullUserGeoipLocation) {
			const { geoipData } = session;
			return { city: geoipData.city, stateCode: geoipData.region, countryCode: geoipData.countryCode };
		}

		return null;
	}, [hasFullUserGeoipLocation, hasFullUserLocation, session]);

	const userLocation = useMemo(() => {
		if (!userLocationObject) return null;

		const { city, stateCode, countryCode } = userLocationObject;
		if (!city || !stateCode || !countryCode) return null;

		const cityFormatted = city.replaceAll(' ', '');
		const slug = [cityFormatted, stateCode, countryCode].join('--').toLowerCase();
		const name = [city, stateCode].join(', ');

		return { slug, name, shortName: city };
	}, [userLocationObject]);

	return { userLocation };
}
