/* eslint-disable react/forbid-elements */
// Disabling the rule to remove keyboard navigation focus ring that ds2 Link element had
import React from 'react';

import { Avatar, FocusRing, Text } from '@change/tomato-core-ui';
import { ArrowLeftIcon, ArrowRightIcon } from '@change/tomato-icons';

import { Badge } from '../Badge';

import { useTopImpactStoriesMobile } from './hooks/useTopImpactStoriesMobile';

export const TopImpactStoriesMobile: React.FC = () => {
	const {
		data: { carouselStories, currentStoryIndex, containerRef, storyRefs },
		actions: { onLeftClick, onRightClick, genDataTestId, trackStoryClick, onStoryFocus, onScroll },
	} = useTopImpactStoriesMobile();

	return (
		<div className="relative z-10 lg:hidden">
			<div
				className="scroll-snap-x scroll-snap-mandatory flex snap-x snap-mandatory gap-20 overflow-x-auto pb-2"
				data-testid="top-impact-stories-mobile-container"
				ref={containerRef}
				// eslint-disable-next-line react/forbid-dom-props
				style={{
					WebkitOverflowScrolling: 'touch',
					scrollbarWidth: 'none',
					msOverflowStyle: 'none',
				}}
				onScroll={onScroll}
			>
				{carouselStories.map((story, index) => (
					<div
						key={`${story.key}-mobile`}
						className="flex flex-shrink-0 snap-center flex-col items-center justify-center py-8"
						ref={(el) => (storyRefs.current[index] = el)}
					>
						<div className="relative h-[180px] w-[180px]">
							<div className="group relative">
								<div
									className={`absolute inset-[-10px] z-0 rounded-full transition-all duration-300 ease-in-out will-change-transform ${
										index === currentStoryIndex ? 'opacity-100' : 'opacity-0'
									} focus-visible:opacity-100`}
								>
									<div className="h-full w-full rounded-full bg-surface-accent-secondary-pressed blur-lg filter" />
								</div>

								<a
									href={story.url}
									className="z-10 block focus-visible:outline-transparent"
									onClick={() => trackStoryClick(story.url)}
									aria-label={story.ariaLabel}
									data-testid={genDataTestId(story.key, 'link')}
									onFocus={() => onStoryFocus(index)}
									target="_blank"
									rel="noreferrer noopenner"
									tabIndex={story.tabIndex}
									aria-hidden={story.ariaHidden}
								>
									<Avatar
										src={story.image}
										size={180}
										className="-webkit-backface-visibility-hidden relative z-10 rounded-full object-cover shadow-lg"
										alt={story.altText}
										data-testid={genDataTestId(story.key, 'image')}
									/>
								</a>

								<div
									data-testid={genDataTestId(story.key, 'badge')}
									className="absolute left-1/2 top-full z-20 -translate-x-1/2 transform"
									aria-hidden={story.ariaHidden}
								>
									<Badge labelTitle={story.labelTitle} labelText={story.labelText} />
								</div>
							</div>
						</div>
					</div>
				))}
			</div>

			<div className="flex h-28 items-center justify-center gap-4">
				<FocusRing focusRingClass="outline-2">
					<button type="button" onClick={onLeftClick} aria-label="Previous story" data-testid="left-arrow">
						<ArrowLeftIcon className="text-content-accent" size="large" />
					</button>
				</FocusRing>

				<Text as="p" className="mt-2 line-clamp-4 w-60 overflow-hidden text-ellipsis text-center">
					{carouselStories[currentStoryIndex].summary}
				</Text>
				<FocusRing focusRingClass="outline-2">
					<button type="button" onClick={onRightClick} aria-label="Next story" data-testid="right-arrow">
						<ArrowRightIcon className="text-content-accent" size="large" />
					</button>
				</FocusRing>
			</div>
		</div>
	);
};
