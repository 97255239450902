/* eslint-disable max-lines-per-function */
import { Button, ChangeDot, HeroLayout, Surface, Text } from '@change/tomato-core-ui';
import { LocationPinIcon } from '@change/tomato-icons';

import { Translate, TranslatePluralHtml } from '@change-corgi/core/react/i18n';

import { useLocalSection } from './hooks/useLocalSection';

export function LocalSection() {
	const {
		data: { localDataValue },
		actions: { onLocalSectionClick },
	} = useLocalSection();

	if (localDataValue.shouldHide) {
		return null;
	}

	return (
		<Surface className="rounded" color="surface-neutral" as="section">
			<HeroLayout
				reverseMd
				reverseSm
				variant="1/2"
				media={
					<div className="flex h-full items-center space-y-4 bg-[url(https://static.change.org/images/map-pattern.svg)] bg-cover bg-center px-[61px] py-10 sm:p-6">
						<div className="space-y-4 md:space-y-2">
							{/* Contains Signatures */}
							<div className="flex items-baseline gap-3">
								<div>
									<ChangeDot size="medium" />
								</div>
								<div>
									<Text
										className="lg:leading-[40px]"
										variant="body"
										size="x-large"
										data-testid="local-section-signature-count-plural"
									>
										<TranslatePluralHtml
											value="corgi.homepage.local_section.stats.signatures"
											count={localDataValue.value.localData.signaturesCount}
											replacements={{
												signatureCountLocalized: localDataValue.value.localData.signaturesCountLocalized,
											}}
										/>
									</Text>
								</div>
							</div>
							{/* Contains Petitions */}
							<div className="flex items-baseline gap-3">
								<div>
									<ChangeDot size="medium" />
								</div>
								<div>
									<Text
										className="lg:leading-[40px]"
										variant="body"
										size="x-large"
										data-testid="local-section-petition-count-plural"
									>
										<TranslatePluralHtml
											value="corgi.homepage.local_section.stats.petitions"
											count={localDataValue.value.localData.petitionsCount}
											replacements={{
												petitionCountLocalized: localDataValue.value.localData.petitionsCountLocalized,
											}}
										/>
									</Text>
								</div>
							</div>
							{/* Contains Decision Makers */}
							<div className="flex items-baseline gap-3">
								<div>
									<ChangeDot size="medium" />
								</div>
								<div>
									<Text
										className="lg:leading-[40px]"
										variant="body"
										size="x-large"
										data-testid="local-section-decision-makers-count-plural"
									>
										<TranslatePluralHtml
											value="corgi.homepage.local_section.stats.decision_makers"
											count={localDataValue.value.localData.decisionMakersCount}
											replacements={{
												decisionMakerCountLocalized: localDataValue.value.localData.decisionMakersCountLocalized,
											}}
										/>
									</Text>
								</div>
							</div>
						</div>
					</div>
				}
			>
				<div className="flex items-center p-8 sm:p-6 md:p-12">
					{/* Outer Wrapper */}
					<div className="space-y-[24px]">
						<div className="space-y-[16px]">
							{/* Contains "Happening in Los Angeles" */}
							<div>
								<div className="inline-block">
									{/* Happening In Location */}
									<Text as="h2" variant="heading" className="m-0">
										<Translate
											value="corgi.homepage.local_section.title"
											replacements={{ location: localDataValue.value.userLocation.shortName }}
										/>
									</Text>
								</div>
							</div>
							{/* Contains "Showing Results for Location" */}
							<div>
								<LocationPinIcon className="align-middle" />
								{/* Showing results for */}
								<Text variant="body" size="medium" className="ml-2 align-middle leading-normal">
									<Translate
										value="corgi.homepage.local_section.subheading"
										replacements={{ location: localDataValue.value.userLocation.name }}
									/>
								</Text>
							</div>
						</div>
						<div>
							{/* Contains "Explore Local Petitions" Button */}
							<div>
								<Button
									as="a"
									href={localDataValue.value.localPageUrl}
									variant="secondary"
									size="large"
									data-test-id="homepage-v2-local-section-explore-button"
									className="bg-transparent"
									onClick={onLocalSectionClick}
								>
									<Translate value="corgi.homepage.local_section.explore_button" />
								</Button>
							</div>
						</div>
					</div>
				</div>
			</HeroLayout>
		</Surface>
	);
}
