export function useScrollToStory(
	containerRef: React.RefObject<HTMLDivElement>,
	storyRefs: React.MutableRefObject<Array<HTMLDivElement | null>>,
) {
	return (
		index: number,
		options: {
			behavior?: ScrollBehavior;
			isJumpingFromClone?: boolean;
		} = {},
	) => {
		const container = containerRef.current;
		const target = storyRefs.current[index];

		if (!container || !target) return;

		const { behavior = 'smooth', isJumpingFromClone = false } = options;

		const scroll = () => {
			const targetCenter = target.offsetLeft + target.offsetWidth / 2;
			const containerCenter = container.offsetWidth / 2;
			const scrollTarget = targetCenter - containerCenter;

			container.scrollTo({ left: scrollTarget, behavior });
		};

		// When jumping from a clone to the real story, we need to temporarily prevent snapping behavior.
		if (isJumpingFromClone) {
			container.style.scrollSnapType = 'none';
			scroll();

			// TODO: The timeout is used purely for the test. Without the timeout, could not find a way to assert that scrollSnapType was temporarily set to none before it resets to x mandatory.
			setTimeout(() => {
				container.style.scrollSnapType = 'x mandatory';
			}, 0);
		} else {
			scroll();
		}
	};
}
