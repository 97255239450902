import { Helmet } from 'react-helmet-async';

import { usePageOrganizationSchema } from './hooks/usePageOrganizationSchema';

export function PageOrganizationSchema() {
	const {
		data: { schemaJson },
	} = usePageOrganizationSchema();
	return (
		<Helmet>
			<script type="application/ld+json">{schemaJson}</script>
		</Helmet>
	);
}
