import React from 'react';

import { Surface, Text } from '@change/tomato-core-ui';

import { translationKey } from '@change-corgi/core/i18n';
import { Translate } from '@change-corgi/core/react/i18n';

import { useEasyBrief } from './hooks/useEasyBrief';

export const EasyBrief: React.FC = () => {
	const {
		data: { briefs },
	} = useEasyBrief();

	return (
		<Surface color="surface-neutral" className="py-6">
			<div className="container">
				<div className="mb-8 flex justify-center text-center">
					<Text as="h2" variant="heading">
						<Translate value={translationKey('corgi.homepage.how_to.easy_brief.title')} />
					</Text>
				</div>
				<div className="relative flex flex-col justify-center gap-4 md:flex-row md:gap-8">
					{briefs.map((brief, index) => (
						<div key={brief.key} className="relative flex flex-1 flex-row gap-4 md:flex-col md:gap-0">
							{index !== briefs.length - 1 && (
								<>
									<div className="absolute left-1/2 top-4 hidden w-[calc(100%-2rem)] translate-x-8 border-t-2 border-dotted border-stroke-strong md:block" />
									<div className="absolute left-4 top-10 block h-[calc(100%-2rem)] border-l-2 border-dotted border-stroke-strong md:hidden" />
								</>
							)}

							<div className="justify-left relative flex items-start md:items-center md:justify-center">
								<div className="mb-2 flex h-8 w-8 items-center justify-center rounded-full bg-content-primary text-lg font-bold text-white">
									{index + 1}
								</div>
							</div>

							<div className="text-left md:text-center">
								<Text as="div" fontWeight="bold" variant="body">
									<Translate value={brief.titleKey} />
								</Text>
								<Text variant="body">
									<Translate value={brief.descriptionKey} />
								</Text>
							</div>
						</div>
					))}
				</div>
			</div>
		</Surface>
	);
};
